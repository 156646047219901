<style>
  input {
    @apply py-1 px-2 rounded h-8;
  }
  .settings-icon {
    @apply bg-gray-300 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 mr-2;
  }
</style>

<template>
  <div class="flex gap-4 justify-center 2xl:container 2xl:mx-auto">
    <div ref="menu" class="flex-grow z-30 dark:bg-black w-full ml-4"  :class="[showMenu ? 'absolute w-full' : 'hidden md:block']">
        <div class="max-h-full overflow-y-auto bg-white dark:bg-gray-900 rounded-md shadow p-3 pb-6 scrollbar sticky top-20">
          <div class="p-1 bg-white dark:bg-gray-900 rounded-md">
          <SuNavigation
            :isSettingsView="true"
            @onTeamSelected="onTeamSelected"
            @onTeamReset="onTeamReset"
          ></SuNavigation>
          <button v-if="team" class="mt-3 w-full" @click="syncPlayers()">{{$t('synch_from_su')}}</button>
          <button class="primary mt-3 w-full" @click="save()">{{$t('save')}}</button>
        </div>
        <Footer :class="[showMenu ? 'py-0 my-0 pt-4' : 'fixed left-0 bottom-0']"></Footer>
        </div>
      </div>
      <div class="flex-grow flex-shrink-0 bg-white dark:bg-gray-900 rounded-md shadow p-3 mb-5 ">
        <template v-if="team">
          <div v-for="(value, position, index) in players" :key="position">
            <div class="mb-1 font-semibold dark:text-white" :class="{'mt-4': index !== 0}">{{position}}</div>
            <div class="flex text-sm flex-col">
            <div class="flex mb-1" v-for="(player, index) in value" :key="index">
              <div class="mr-2 w-32"><v-select v-model="player.position" :options="Object.keys(players)" @changed="positionChanged()"></v-select></div>
              <div class="mr-2"><input class="w-10 text-center" type="text" v-model="player.nr"></div>
              <div class="mr-2"><input type="text" v-model="player.name"></div>
              <div class="mr-3"><input class="w-16 text-center" type="text" v-model="player.year"></div>
              <div class="flex-grow">
                <div class="flex w-full justify-between">
                  <div class="flex">
                    <div @click="updateTopscorer(players, position, index)" class="cursor-pointer rounded settings-icon">
                      <svg class="cursor-pointer fill-current p-2 h-8 w-8 dark:text-gray-300 rounded" :class="{'bg-red-500 hover:bg-red-400': player.topscorer}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 11c0 2.206 1.794 4 4 4 1.761 0 3.242-1.151 3.775-2.734l2.224-1.291.001.025c0 3.314-2.686 6-6 6s-6-2.686-6-6 2.686-6 6-6c1.084 0 2.098.292 2.975.794l-2.21 1.283c-.248-.048-.503-.077-.765-.077-2.206 0-4 1.794-4 4zm4-2c-1.105 0-2 .896-2 2s.895 2 2 2 2-.896 2-2l-.002-.015 3.36-1.95c.976-.565 2.704-.336 3.711.159l4.931-2.863-3.158-1.569.169-3.632-4.945 2.87c-.07 1.121-.734 2.736-1.705 3.301l-3.383 1.964c-.29-.163-.621-.265-.978-.265zm9.957 2.858c-.301-.483-1.066-1.061-1.962-.947l.005.089c0 4.411-3.589 8-8 8s-8-3.589-8-8 3.589-8 8-8c1.475 0 2.853.408 4.041 1.107.334-.586.428-1.544.146-2.18-1.275-.589-2.69-.927-4.187-.927-5.523 0-10 4.477-10 10 0 2.958 1.291 5.607 3.332 7.438l-1.307 4.052c-.123.383.35.679.64.399l3.002-2.888c1.312.634 2.778.999 4.333.999 1.556 0 3.023-.362 4.335-.996l3 2.886c.29.28.763-.017.64-.399l-1.306-4.05c1.839-1.652 3.065-3.973 3.288-6.583z"/></svg>
                    </div>
                    <div @click="add(players, position, index)" class="cursor-pointer rounded settings-icon">
                      <svg class="cursor-pointer fill-current p-2 h-8 w-8 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                        <path d="M8.05 5.8h4.625a1.125 1.125 0 010 2.25H8.05v4.625a1.125 1.125 0 01-2.25 0V8.05H1.125a1.125 1.125 0 010-2.25H5.8V1.125a1.125 1.125 0 012.25 0V5.8z"/>
                      </svg>
                    </div>
                    <div v-if="index < players[position].length - 1" @click="moveDown(players, position, index)" class="flex-shrink-0 cursor-pointer rounded settings-icon">
                      <svg class="cursor-pointer fill-current p-2 h-8 w-8 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 14">
                        <path transform="matrix(1 0 0 -1 0 14)" d="M8.024 4.1v8.6a1.125 1.125 0 0 1-2.25 0V4.1L2.18 7.695A1.125 1.125 0 1 1 .59 6.104L6.103.588c.44-.439 1.151-.439 1.59 0l5.516 5.516a1.125 1.125 0 0 1-1.59 1.59L8.023 4.1z"/>
                      </svg>
                    </div>
                    <div v-if="index > 0" @click="moveUp(players, position, index)" class="cursor-pointer rounded settings-icon">
                      <svg class="cursor-pointer fill-current p-2 h-8 w-8 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                        <path d="M8.024 4.1v8.6a1.125 1.125 0 01-2.25 0V4.1L2.18 7.695A1.125 1.125 0 11.59 6.104L6.103.588c.44-.439 1.151-.439 1.59 0l5.516 5.516a1.125 1.125 0 01-1.59 1.59L8.023 4.1z"/>
                      </svg>
                    </div>
                  </div>
                  <DeleteButton @onDelete="onDelete(players, position, index)"></DeleteButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-for="(value, title) in staff" :key="title">
            <div class="mt-6 mb-1 font-semibold dark:text-white">{{title}}</div>
            <div class="flex flex-col">
            <div class="flex text-sm mb-2" v-for="(st, index) in value" :key="index">
              <div class="mr-2"><input class="w-48" type="text" v-model="st.name"></div>
              <div class="mr-3"><input class="w-56" type="text" v-model="st.typ"></div>
              <div class="flex flex-grow justify-between">
                <div class="flex">
                  <div @click="add(staff, title, index)" class="flex-shrink-0 cursor-pointer rounded settings-icon">
                    <svg class="cursor-pointer fill-current p-2 h-8 w-8 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                      <path d="M8.05 5.8h4.625a1.125 1.125 0 010 2.25H8.05v4.625a1.125 1.125 0 01-2.25 0V8.05H1.125a1.125 1.125 0 010-2.25H5.8V1.125a1.125 1.125 0 012.25 0V5.8z"/>
                    </svg>
                  </div>
                    <div v-if="index < staff[title].length - 1" @click="moveDown(staff, title, index)" class="flex-shrink-0 cursor-pointer rounded settings-icon">
                      <svg class="cursor-pointer fill-current p-2 h-8 w-8 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 14">
                      <path transform="matrix(1 0 0 -1 0 14)" d="M8.024 4.1v8.6a1.125 1.125 0 0 1-2.25 0V4.1L2.18 7.695A1.125 1.125 0 1 1 .59 6.104L6.103.588c.44-.439 1.151-.439 1.59 0l5.516 5.516a1.125 1.125 0 0 1-1.59 1.59L8.023 4.1z"/>
                    </svg>
                  </div>
                  <div v-if="index > 0" @click="moveUp(staff, title, index)" class="flex-shrink-0 cursor-pointer rounded settings-icon">
                    <svg class="cursor-pointer fill-current p-2 h-8 w-8 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                      <path d="M8.024 4.1v8.6a1.125 1.125 0 01-2.25 0V4.1L2.18 7.695A1.125 1.125 0 11.59 6.104L6.103.588c.44-.439 1.151-.439 1.59 0l5.516 5.516a1.125 1.125 0 01-1.59 1.59L8.023 4.1z"/>
                    </svg>
                  </div>
                </div>
                <DeleteButton @onDelete="onDelete(staff, title, index)"></DeleteButton>
              </div>
            </div>
          </div>
        </div>
     </template>
   </div>
   <div class="hidden lg:block flex-grow w-full">
   </div>
   <AlertSucceed :show="saved" :message="$t('saved_players')"></AlertSucceed>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SuNavigation from '@/components/swissunihockey/SuNavigation'
import DeleteButton from '@/components/DeleteButton'
import VSelect from '@/components/v-select'
import Footer from '@/components/Footer'
import AlertSucceed from '@/components/AlertSucceed'

export default {
  components: {
    AlertSucceed,
    SuNavigation,
    DeleteButton,
    Footer,
    'v-select': VSelect
  },
  props: {
    showMenu: Boolean
  },
  data () {
    return {
      emptyPlayers: [
        { position: 'Goalie', nr: '', name: '', year: '' },
        { position: 'Verteidiger', nr: '', name: '', year: '' },
        { position: 'Stürmer', nr: '', name: '', year: '' }
      ],
      emptyStaff: [
        { title: 'Betreuer', name: '', typ: 'Cheftrainer' },
        { title: 'Betreuer', name: '', typ: 'Assistent' }
      ],
      players: null,
      staff: null,
      saved: false
    }
  },
  computed: {
    ...mapGetters({ team: 'swissunihockey/team' })
  },
  methods: {
    ...mapActions({ updateDetails: 'swissunihockey/updateDetails', getSwPlayers: 'swissunihockey/getSwPlayers' }),
    groupBy (xs, key) {
      return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
      }, {})
    },
    clone (object) {
      return JSON.parse(JSON.stringify(object))
    },
    onTeamSelected (team) {
      this.players = team.players ? this.groupBy(this.clone(team.players), 'position') : this.groupBy(this.emptyPlayers, 'position')
      this.staff = team.staff ? this.groupBy(this.clone(team.staff), 'title') : this.groupBy(this.emptyStaff, 'title')
    },
    onTeamReset () {
      this.players = null
      this.staff = null
    },
    onDelete (collection, key, index) {
      collection[key].splice(index, 1)
    },
    add (collection, key, index) {
      collection[key].splice(index + 1, 0, {})
    },
    moveUp (collection, key, index) {
      this.move(collection[key], index, index - 1)
    },
    moveDown (collection, key, index) {
      this.move(collection[key], index, index + 1)
    },
    move (arr, fromIndex, toIndex) {
      var element = arr[fromIndex]
      arr.splice(fromIndex, 1)
      arr.splice(toIndex, 0, element)
    },
    updateTopscorer (collection, key, index) {
      var player = collection[key][index]
      const v = !player.topscorer

      Object.keys(collection).forEach(key => {
        collection[key].forEach(obj => { obj.topscorer = false })
      })
      player.topscorer = v
      this.$set(collection[key], index, player)
    },
    async syncPlayers () {
      this.players = this.groupBy(await this.getSwPlayers(this.team), 'position')
    },
    save () {
      var players = []
      Object.keys(this.players).forEach(key => {
        players = players.concat(this.players[key])
      })
      var staff = []
      Object.keys(this.staff).forEach(key => {
        staff = staff.concat(this.staff[key])
      })
      this.updateDetails({ players, staff }).then(() => {
        this.saved = true
        setTimeout(async () => {
          this.saved = false
        }, 2000)
      })
    },
    positionChanged () {
      Object.keys(this.players).forEach(position => {
        this.players[position].forEach((player, index) => {
          if (player.position !== position) {
            this.players[position].splice(index, 1)
            this.players[player.position].push(player)
            if (this.players[position].length === 0) {
              delete this.players[position]
            }
          }
        })
      })
    }
  },
  mounted () {
    if (this.team) {
      this.onTeamSelected(this.team)
    }
  }
}
</script>
